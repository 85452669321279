import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Divider,
  Button,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Modal,
  Box,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ImageContainer } from "../ImageContainer";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
        //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
      //@ts-ignore
    boxShadow:({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
       //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,

  },
  btn: {
    minWidth:'fit-content',
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
  modalBtn: {
    minWidth:'271px',
    Width:'fit-content',
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
  img: {
    width: "100%",
    height: "fit-content",
    maxHeight: "185px",
  },
  dropDownText: {
    padding: "10px",
    background: "#e8e7e7",
  },
  options: {
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
  newUsedModal: {
    textAlign:'center',
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  newUsedContent: {
    width: "fit-content",
    minWidth: "300px",
    height: 'fit-content',
    padding: '5%',
    background: 'white',
    borderRadius: '20px',
    alignSelf: 'center',
  },
}));

interface Props {
  base_uri: string | null;
  personalDetails: any | null;
  vehicleSpecs: any | null;
  vehicleImages: any | null;
  onSubmit: Function;
  dealerId: number | null;
  leadSourceId: number | null;
  websiteColors?: any;
  resetForm?: boolean;
  setFocus?: string;
  newCarLink?: string;
  usedCarLink?: string;
  isRedirectOnConfirm?: boolean;
  onSubmitForm?: Function;
  wePay4Cars_uri: string | null;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
}

const ConfirmationContainer: FC<Props> = ({
  base_uri,
  personalDetails,
  vehicleSpecs,
  vehicleImages,
  onSubmit,
  dealerId,
  newCarLink,
  usedCarLink,
  leadSourceId,
  websiteColors,
  resetForm,
  setFocus,
  onSubmitForm,
  wePay4Cars_uri,
  isRedirectOnConfirm,
  paper,
  paperColor,
  paperShadowColor,
}) => {
  const classes = useStyles({ ...websiteColors,  paper, paperColor, paperShadowColor});
  const localStyles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [showNewUsedModal, setShowNewUsedModal] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const redirect = (link: string) => {
    if (
      link &&
      (link.toLowerCase().includes("http") ||
        link.toLowerCase().includes("www"))
    ) {
      window.location.href = `${link}`;
    } else {
      history.push(`${link}`);
    }
  };

  const handleClose = (option: number) => {
    setShowNewUsedModal(false)
    switch (option) {
      case 1:
        redirect(`${newCarLink}`);
        break;
      case 2:
        redirect(`${usedCarLink}`);
        break;
      default:
        onSubmit(true);
        break;
    }
    //confirmDetails(option);
  };

  const confirmDetails = () => {
    if (
      !Object.keys(personalDetails).length ||
      !Object.keys(vehicleSpecs).length ||
      !Object.keys(vehicleImages).length
    ) {
      enqueueSnackbar("Some required details are missing", {
        variant: "warning",
      });
      return;
    }

    if (onSubmitForm) {
      let formValues = {
        ...personalDetails,
        ...vehicleSpecs,
        ...vehicleImages,
      };
      onSubmitForm(formValues);
    }

    scroll();

    setLoading(true);

    saveDetails();
  };

  const resetPersonalDetailsFormValues = () => {
    personalDetails.firstName = "";
    personalDetails.lastName = "";
    personalDetails.mobileNumber = "";
    personalDetails.emailAddress = "";
    personalDetails.city = "";
    personalDetails.province = "";
    personalDetails.country = "";
  };

  const resetVehicleDetailsFormValues = () => {
    vehicleSpecs.licenseDisk = "";
    vehicleSpecs.category = "";
    vehicleSpecs.categoryId = -1;
    vehicleSpecs.make = "";
    vehicleSpecs.makeId = 0;
    vehicleSpecs.model = "";
    vehicleSpecs.modelId = 0;
    vehicleSpecs.variant = "";
    vehicleSpecs.variantId = 0;
    vehicleSpecs.variantCode = "";
    vehicleSpecs.variantStartYear = 0;
    vehicleSpecs.variantEndYear = 0;
    vehicleSpecs.year = "";
    vehicleSpecs.odometer = "";
    vehicleSpecs.color = "";
    vehicleSpecs.vin = "";
    vehicleSpecs.regNo = "";
    vehicleSpecs.transmission = "";
    vehicleSpecs.fuelType = "";
    vehicleSpecs.spareKeys = false;
    vehicleSpecs.serviceBook = false;
    vehicleSpecs.maintenancePlan = false;
    vehicleSpecs.warranty = false;
  };

  const resetVehicleImagesFormValues = () => {
    vehicleImages.front = "";
    vehicleImages.rear = "";
    vehicleImages.leftSide = "";
    vehicleImages.rightSide = "";
  };

  const resetFormValues = () => {
    resetPersonalDetailsFormValues();
    resetVehicleDetailsFormValues();
    resetVehicleImagesFormValues();
  };

  const scroll = () => {
    if (setFocus && !!setFocus) {
      var element = document.getElementById(setFocus);
      //@ts-ignore
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const saveDetails = async () => {
    // Save the various details to WePay4Cars database first, then the lead details & then process the new lead
    try {
      let userResult = await createWp4cUser();
      let user = userResult?.data;

      if (!user) {
        setLoading(false);
        enqueueSnackbar(`Failed to create the user: response is null`, {
          variant: "error",
        });
        return;
      }

      let vehicleResult = await createWp4cVehicle(user.token);
      let listingId = vehicleResult?.data;

      if (!listingId) {
        setLoading(false);
        enqueueSnackbar(`Failed to create the vehicle: response is null`, {
          variant: "error",
        });
        return;
      }

      let vehicleImagesResult = await saveWp4cVehicleImages(
        user.token,
        listingId
      );

      if (!vehicleImagesResult?.data) {
        setLoading(false);
        enqueueSnackbar(`Failed to save the vehicle images: response is null`, {
          variant: "error",
        });
        return;
      }

      let confirmedListingResult = await confirmTrade(user.token, listingId);
      let confirmedListing = confirmedListingResult?.data;

      if (!confirmedListing) {
        setLoading(false);
        enqueueSnackbar(`Failed to confirm the trade: response is null`, {
          variant: "error",
        });
        return;
      }

      let leadResult = await createLead();
      let lead = leadResult?.data;

      if (!lead) {
        setLoading(false);
        enqueueSnackbar(`Failed to create the lead: response is null`, {
          variant: "error",
        });
        return;
      }

      let tradeInResult = await createTradeIn(lead.id, confirmedListing);
      let tradeIn = tradeInResult?.data;

      if (!tradeIn) {
        setLoading(false);
        enqueueSnackbar(
          `Failed to save the trade-in details: response is null`,
          { variant: "error" }
        );
        return;
      }

      processLead(lead.id);

      

    } catch (error) {
      setLoading(false);
      enqueueSnackbar(`Failed to save the details: ${error}`, {
        variant: "error",
      });
    }
  };

  const createWp4cUser = () => {
    let data = {
      ...personalDetails,
      idNumber: "1234567890123",
      isExternal: true,
      driversLicense:
        "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg==",
      profilePicture:
        "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg==",
    };
    // @ts-ignore
    return axios.post(`${wePay4Cars_uri}/User`, data);
  };

  const createWp4cVehicle = (token: string) => {
    let licenseDiskBase64 =
      !vehicleSpecs.licenseDisk || vehicleSpecs.licenseDisk.length <= 0
        ? "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg=="
        : vehicleSpecs.licenseDisk.split(",")[1];

    let data = {
      ...vehicleSpecs,
      vehicleVariantId: vehicleSpecs.variantId,
      licenseDisk: licenseDiskBase64,
      registration: vehicleSpecs.regNo,
      transmissionType: vehicleSpecs.transmission,
    };
    // @ts-ignore
    return axios.post(`${wePay4Cars_uri}/vehicle`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  };

  const saveWp4cVehicleImages = (token: string, listingId: number) => {
    let images: any = [];

    Object.keys(vehicleImages).map((key, value) => {
      images.push({
        image: vehicleImages[key].split(",")[1],
        imageType: key,
      });
    });
    // @ts-ignore
    return axios.put(`${wePay4Cars_uri}/vehicle/image`, images, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      params: {
        vehicleListingId: listingId,
      },
    });
  };

  const confirmTrade = (token: string, listingId: number) => {
    // @ts-ignore
    return axios.post(`${wePay4Cars_uri}/vehicle/confirmTrade`, null, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      params: {
        vehicleListingId: listingId,
      },
    });
  };

  const createLead = () => {
    let data = {
      name: `${personalDetails?.firstName} ${personalDetails?.lastName}`,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      phoneNumber: personalDetails?.mobileNumber,
      emailAddress: personalDetails?.emailAddress,
      location: `${personalDetails.city}, ${personalDetails.province}, ${personalDetails.country}`,
      availability: personalDetails?.availability,
      dealerId: dealerId,
      leadSourceId: leadSourceId,
      leadTypeId: 11, //tradein
      TypeCode: "TRADEIN",
      notes: "",
      dateUpdated: new Date().toISOString(),
    };
    // @ts-ignore
    return axios.post(`${base_uri}/leads`, data);
  };

  const createTradeIn = (leadId: number, confirmedListing: any) => {
    const { vehicle } = confirmedListing;
    const { variant, uploads } = vehicle;
    const { model } = variant;

    let images = [];

    if (uploads && uploads?.length > 0) {
      for (let i = 0; i < uploads?.length; i++) {
        let image = {
          imageUrl: `${wePay4Cars_uri?.replace("/api", "")}/${
            uploads[i]?.imageUrl
          }`,
          imageOrientation: uploads[i]?.type?.id,
        };

        images.push(image);
      }
    }

    let data = {
      leadId: leadId,
      wePay4CarsListingId: confirmedListing?.id,
      vinNumber: vehicle?.vin,
      year: parseInt(vehicle?.year),
      transmission: vehicle?.transmission?.toString(),
      fuelType: vehicle?.fuelType?.toString(),
      odometer: vehicle?.odometer,
      color: vehicle?.color,
      hasSpareKeys: vehicle?.hasSpareKeys,
      hasServiceBook: vehicle?.hasServiceBook,
      hasWarranty: vehicle?.hasWarranty,
      hasMaintenancePlan: vehicle?.hasMaintenancePlan,
      registration: vehicle?.registration,
      variant: {
        name: variant?.name,
        mmCode: variant?.mmCode,
        introYear: variant?.startYear,
        discontinueYear: variant?.endYear,
      },
      model: {
        name: model?.name,
      },
      make: {
        name: model?.manufacturer?.name,
      },
      tradeInVehicleImages: images,
    };
    // @ts-ignore
    return axios.post(`${base_uri}/TradeInVehicle`, data);
  };

  const processLead = (leadId: number) => {
    // @ts-ignore
    axios.post(`${base_uri}/Leads/ProcessLead/${leadId}`, {})
      .then((response) => {
        setLoading(false);
        enqueueSnackbar(
          "Successfully saved and submitted the captured details",
          { variant: "success" }
        );

        if (resetForm && resetForm == true) {
          resetFormValues();
        }

        
        
        if (isRedirectOnConfirm) {
          setShowNewUsedModal(true);
        }else{
          setTimeout(() => {
            onSubmit(true);
          }, 500);
        }
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Failed to save the vehicle details", {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <div className={classes.holder}>
        <Modal
          open={showNewUsedModal}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box className={classes.newUsedModal}>
            <Box className={classes.newUsedContent}>
              <h2 id="parent-modal-title">Sell Vehicle Process Complete</h2>
              <p id="parent-modal-description">
              We have Successfully saved and submitted the captured details.
              </p>
              <Grid item xs={12} container justify="center">
              <div>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.modalBtn}
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? "composition-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                      >
                        Done
                      </Button>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom-start"
                                  ? "left top"
                                  : "left bottom",
                            }}
                          >
                            <Paper>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                              >
                                <div className={classes.dropDownText}>
                                  Are you looking for a new or used car?
                                </div>
                                <MenuItem
                                  onClick={() => handleClose(1)}
                                  className={classes.options}
                                >
                                  New Car
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleClose(2)}
                                  className={classes.options}
                                >
                                  Used Car
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleClose(0)}
                                  className={classes.options}
                                >
                                  Not Interested
                                </MenuItem>
                              </MenuList>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
              </Grid>
            </Box>
          </Box>
        </Modal>
        {loading && (
          <>
            <CardHeader
              className="p-4"
              title={"Submitting the captured details"}
            />

<div className={classes.holderInner}>
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            </div>
          </>
        )}
        {!loading && (
          <>
            <CardHeader
              className="p-4"
              title={"Please confirm your details are correct"}
            />

<div className={classes.holderInner}>
              <Grid container spacing={4}>
                <Grid item xs={12} container spacing={1} direction="row">
                  {!Object.keys(personalDetails).length && (
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textSecondary">
                        No personal information available
                      </Typography>
                    </Grid>
                  )}
                  {Object.keys(personalDetails).length > 0 && (
                    <>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Full Name
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>
                            {personalDetails.firstName}{" "}
                            {personalDetails.lastName}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Mobile Number
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{personalDetails.mobileNumber}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Email Address
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{personalDetails.emailAddress}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          When would you like to sell your vehicle?
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{personalDetails.availability}</strong>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Divider />

                <Grid item xs={12} container spacing={1}>
                  {!Object.keys(vehicleSpecs).length && (
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textSecondary">
                        No vehicle information available
                      </Typography>
                    </Grid>
                  )}
                  {Object.keys(vehicleSpecs).length > 0 && (
                    <>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Make
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.make}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Model
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.model}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Year
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.year}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Variant
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.variant}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Odometer Reading
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.odometer}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Color
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.color}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          VIN Number
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.vin}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Registration Number
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.regNo}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Transmission
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.transmission}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Fuel Type
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.fuelType}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Spare Keys
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>
                            {vehicleSpecs.spareKeys ? "Yes" : "No"}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Service Book
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>
                            {vehicleSpecs.serviceBook ? "Yes" : "No"}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Maintenance Plan
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>
                            {vehicleSpecs.maintenancePlan ? "Yes" : "No"}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                        >
                          Warranty
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>
                            {vehicleSpecs.warranty ? "Yes" : "No"}
                          </strong>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Divider />

                <Grid item xs={12} container spacing={1}>
                  {!Object.keys(vehicleImages).length && (
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textSecondary">
                        No vehicle photos available
                      </Typography>
                    </Grid>
                  )}
                  {Object.keys(vehicleImages).length > 0 && (
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={2}
                      justifyContent="space-between"
                      direction="row"
                    >
                      {Object.keys(vehicleImages).map((key) => (
                        <Grid item xs={12} sm={6} md={4}>
                          {/* <ImageContainer aspectRatio={(16 / 10)} height='100%' src={vehicleImages[key].toString()} alt={`Vehicle ${key} image`} /> */}
                          <img
                            alt={`Vehicle ${key} image`}
                            src={vehicleImages[key]}
                            className={classes.img}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>

                <Divider />
                  <Grid item xs={12} container justify="flex-end">
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => confirmDetails()}
                    >
                      Confirm
                    </Button>
                  </Grid>

                
              </Grid>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default ConfirmationContainer;
