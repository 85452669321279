
import React, { FC } from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
  //@ts-ignore
import { ReactNode } from "react-transition-group/node_modules/@types/react";

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(20, 0, 6),
        paddingTop: '0'
    },
    dividerTitle: {
        backgroundColor: '#06141f',
        width: '33%',
        marginBottom: '20px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    url: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none',
        }
    },
    detailsParagraph: {
        textAlign: 'left',
        fontSize: '1.8rem'
    },
    link: {
        backgroundColor: 'transparent',
        marginBottom: '1rem',
        color: '#3f51b5',
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8
        },
        //fontWeight: '700',
        fontWeight: 700
    },
    typoHeading: {
        textAlign: 'center',
        color: 'textPrimary',
        fontWeight: 500,
    },
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    flex: {
        display: "flex"
    },

    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    noSideDetails: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

interface Props {
    base_uri: string,
    variant: "standard",
    mainTitle: string;
    leftContentTitle?: string,
    rightContentTitle?: string,
    leftContent?: ReactNode,
    rightContent?: ReactNode,
}

const SideBarsContainer: FC<Props> = ({ base_uri, variant, mainTitle, leftContentTitle, rightContentTitle, leftContent, rightContent }) => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
                <Typography gutterBottom variant="h5" className={classes.typoHeading}>
                    {mainTitle}
                </Typography>
                <Divider className={classes.dividerTitle} />
                <Grid container spacing={2} className={leftContent !== null || rightContent ? classes.noSideDetails : ''}>
                    <Grid item xs={12} md={5}>
                        {leftContentTitle && <Typography gutterBottom variant="h5" className={classes.typoHeading}>
                            {leftContentTitle}

                        </Typography>}
                        {leftContent}
                    </Grid>
                    <Grid item xs={12} md={5}>
                        {rightContentTitle && <Typography gutterBottom variant="h5" className={classes.typoHeading}>
                            {rightContentTitle}

                        </Typography>}
                        {rightContent}
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default SideBarsContainer;