import React, { FC, useEffect, useState} from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    btnTxt: {
        color: 'white !important',
        outline: '0 !important'
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        zIndex: 1,
        top: 0
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    dropzone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3rem',
        outline: '0 !important'
    },
    container: {
        position : 'relative',
        minHeight: '185px',
        width: '100%'
    },
    dropzoneMessage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deleteButton: {
        backgroundColor: '#d4411e',
        borderRadius: 0,
        position: 'absolute',
        zIndex: 100,
        right: 10,
        top: 10,
        outline: '0 !important'
    }      
}));

interface Props {
    id: string;
    title: string;
    image: any;
    small?: boolean;
    required?: boolean;
    disabled?: boolean;
    error?: any;
    errorMsg?: string;
    className?: string;
    onDropFile: Function;
    clear: Function;
}

const ImageUploader: FC<Props> = ({
    id,
    title,
    image,
    small = false,
    required = false,
    disabled = false,
    error,
    errorMsg,
    className,
    onDropFile,
    clear
}) => {

    const classes = useStyles(small);

    const [ loading, setLoading ] = useState(false);
    const [ files, setFiles ] = useState<any[]>([]);
    const [ isDragAccept, setDragAccept ] = useState(false);
    
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles: any[]) => {
            setDragAccept(true);
            setFiles(acceptedFiles.map((file) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            onDropFile(Object.assign(acceptedFiles[0]), id);
        }
    });

    const clearImage = () => {
        clear(id);
        setDragAccept(false);
        setFiles([]);
    }

    const thumbs = files.map((file, index) => (
        <GridListTile key={index}>
            <img src={file.preview} style={{width: '100%', height: '100%'}} onLoad={() => setLoading(false)} alt={file.name} />
            {
                !disabled && 
                <IconButton 
                    aria-label="delete"
                    classes={{label: classes.btnTxt}}
                    onClick={clearImage}
                    color="primary"
                    className={classes.deleteButton}
                >
                    <DeleteIcon />
                </IconButton>
            }
        </GridListTile>
    ));

    useEffect(() => () => {

        files.forEach(file => URL.revokeObjectURL(file.preview));

    }, [files]);

    useEffect(() => {

        if (image) {

            setLoading(true);
            setDragAccept(true);
            let reader = new FileReader();

            if (image.preview && image instanceof File) {

                reader.readAsDataURL(image);
                reader.onload = function(e) {
                    setFiles([ { preview: e?.target?.result } ])
                };

            } else {

                setFiles([ { name: id, preview: (image?.image || image?.src || image ) }]);

            }

        } else {

            clearImage();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    return (
        <section className={className}>
            <Typography variant="body2" color="textSecondary" gutterBottom component="p">
                {title} {required && ' *'}
            </Typography>
            <div className={classes.container}>
                <div className={classes.dropzone} style={files.length === 0 ? {border: '2px dashed lightgrey'}: {}} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={classes.dropzoneMessage}>
                        {
                            loading
                            ?   <CircularProgress/>
                            :   files.length === 0 &&
                                <React.Fragment>
                                    <CloudUploadOutlinedIcon color='disabled' fontSize='large' />
                                    {
                                        !small &&
                                        <Typography variant="body2" className="mt-2" color="textSecondary" component="p">
                                            Click or drag image here to upload
                                        </Typography>
                                    }
                                </React.Fragment>
                        }
                    </div>
                </div>
                {
                    files.length > 0 &&
                    <div className={classes.thumbsContainer} style={{position: isDragAccept ? 'absolute': 'relative'}}>
                        <GridList cols={1} style={{ width: '100%', minHeight: isDragAccept ? '170px' : 'initial', height: '100%' }}>
                            {thumbs}
                        </GridList>
                    </div>
                }
            </div>
            {
                error && 
                <p className={classes.errorText}>A {title} image is required</p>
            }
        </section>
    );
}

export default ImageUploader;