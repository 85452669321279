import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Grid, Typography, Divider, Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import { useSnackbar } from "notistack";
import { ImageContainer } from "../ImageContainer";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
    //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
    //@ts-ignore
    boxShadow: ({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
    //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,

  },
  btn: {
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
      opacity: 0.8,
    },
  },
  img: {
    width: "100%",
    height: "fit-content",
    maxHeight: "185px",
  },
  cardPadding: {
    padding: '0px',
    margin: '15px 0px',
  }
}));

interface Props {
  base_uri: string | null;
  personalDetails: any | null;
  vehicleSpecs: any | null;
  vehicleImages: any | null;
  onSubmit: Function;
  dealerId: number | null;
  leadSourceId: number | null;
  websiteColors?: any;
  resetForm?: boolean;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
  setFocus?: string;
  onSubmitForm?: Function;
}

const ConfirmationContainer: FC<Props> = ({
  base_uri,
  personalDetails,
  vehicleSpecs,
  vehicleImages,
  onSubmit,
  dealerId,
  leadSourceId,
  websiteColors,
  paper,
  paperColor,
  paperShadowColor,
  resetForm,
  setFocus,
  onSubmitForm
}) => {
  const classes = useStyles({ ...websiteColors, paper, paperColor, paperShadowColor });
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const confirmDetails = () => {
    if (
      !Object.keys(personalDetails).length ||
      !Object.keys(vehicleSpecs).length ||
      !Object.keys(vehicleImages).length
    ) {
      enqueueSnackbar("Some required details are missing", { variant: "warning" });
      return;
    }

    if (onSubmitForm) {
      let formValues = { ...personalDetails, ...vehicleSpecs, ...vehicleImages }
      onSubmitForm(formValues);
    }

    scroll();
    setLoading(true);

    createLead();
  };

  const resetPersonalDetailsFormValues = () => {
    personalDetails.firstName = "";
    personalDetails.lastName = "";
    personalDetails.phone = "";
    personalDetails.email = "";
  }

  const resetVehicleDetailsFormValues = () => {
    vehicleSpecs.licenseDisk = "";
    vehicleSpecs.make = "";
    vehicleSpecs.makeId = 0;
    vehicleSpecs.model = "";
    vehicleSpecs.modelId = 0;
    vehicleSpecs.variant = "";
    vehicleSpecs.variantId = 0;
    vehicleSpecs.variantCode = "";
    vehicleSpecs.variantStartYear = 0;
    vehicleSpecs.variantEndYear = 0;
    vehicleSpecs.year = "";
    vehicleSpecs.odometer = "";
    vehicleSpecs.color = "";
    vehicleSpecs.vin = "";
    vehicleSpecs.regNo = "";
    vehicleSpecs.transmission = "";
    vehicleSpecs.fuelType = "";
    vehicleSpecs.spareKeys = false;
    vehicleSpecs.serviceBook = false;
    vehicleSpecs.maintenancePlan = false;
    vehicleSpecs.warranty = false;
  }

  const resetVehicleImagesFormValues = () => {
    vehicleImages.front = '';
    vehicleImages.rear = '';
    vehicleImages.left = '';
    vehicleImages.right = '';
  }
  const resetFormValues = () => {
    resetPersonalDetailsFormValues();
    resetVehicleDetailsFormValues();
    resetVehicleImagesFormValues();
  }

  const createLead = async () => {
    let params = {
      leadStatusId: 1,
      leadTypeId: 11, //tradein
      dealerId: dealerId,
      name: `${personalDetails?.firstName} ${personalDetails?.lastName}`,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      phoneNumber: personalDetails?.phone,
      emailAddress: personalDetails?.email,
      leadSourceId: leadSourceId,
      TypeCode: "TRADEIN",
      notes: "",
      dateUpdated: new Date().toISOString(),
    };
    // @ts-ignore
    axios.post(`${base_uri}/leads`, params)
      .then((response) => {
        createTradeIn(response?.data?.id);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Failed to save the personal details", { variant: "error" });
      });
  };

  const createTradeIn = (leadId: number) => {
    /*
        Note (2021-11-22): need to find a better way to specify the orientation. This is now hardcoded
        according to the enum in the backend:

          public enum VehicleImageOrientation
          {
              Thumbnail,
              Front,
              Rear,
              LeftSide,
              RightSide,
              InteriorCenter,
              InteriorOdometer,
              LicenseDisk
          }
    */

    let imageDto = [
      {
        imageOrientation: 1,
        imageUrl: vehicleImages.front,
      },
      {
        imageOrientation: 2,
        imageUrl: vehicleImages.rear,
      },
      {
        imageOrientation: 3,
        imageUrl: vehicleImages.left,
      },
      {
        imageOrientation: 4,
        imageUrl: vehicleImages.right,
      },
    ];

    let params = {
      leadId: leadId,
      makeId: vehicleSpecs?.makeId,
      modelId: vehicleSpecs?.modelId,
      variantId: vehicleSpecs?.variantId,
      year: vehicleSpecs?.year,
      odometer: vehicleSpecs?.odometer,
      color: vehicleSpecs?.color,
      vinNumber: vehicleSpecs?.vin,
      registration: vehicleSpecs?.regNo,
      transmission: vehicleSpecs?.transmission,
      fuelType: vehicleSpecs?.fuelType,
      hasSpareKeys: vehicleSpecs?.hasSpareKeys,
      hasServiceBook: vehicleSpecs?.hasServiceBook,
      hasMaintenancePlan: vehicleSpecs?.hasMaintenancePlan,
      hasWarranty: vehicleSpecs?.hasWarranty,
      tradeInVehicleImages: imageDto,
    };
    // @ts-ignore
    axios.post(`${base_uri}/TradeInVehicle/FromShared`, params)
      .then((response) => {
        processLead(leadId);

      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Failed to save the vehicle details", { variant: "error" });
      });
  };
  const scroll = () => {
    if (setFocus && !!setFocus) {
      var element = document.getElementById(setFocus);
      //@ts-ignore
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
  const processLead = (leadId: number) => {
    // @ts-ignore
    axios.post(`${base_uri}/Leads/ProcessLead/${leadId}`, {})
      .then((response) => {
        setLoading(false);
        enqueueSnackbar("Successfully saved and submitted the captured details", { variant: "success" });

        if (resetForm && resetForm == true) {
          resetFormValues()
        }

        setTimeout(() => {
          onSubmit(true);
        }, 500);

      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Failed to save the vehicle details", { variant: "error" });
      });
  };

  return (
    <React.Fragment>
      <div className={classes.holder}>
        {loading && (
          <>
            <CardHeader className={classes.cardPadding} title={"Submitting the captured details"} />

            <div className={classes.holderInner}>
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            </div>
          </>
        )}
        {!loading && (
          <>
            <CardHeader className={classes.cardPadding} title={"Please confirm your details are correct"} />


            <div className={classes.holderInner}>
              <Grid container spacing={4}>
                <Grid item xs={12} container spacing={1} direction="row">
                  {!Object.keys(personalDetails).length && (
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textSecondary">
                        No personal information available
                      </Typography>
                    </Grid>
                  )}
                  {Object.keys(personalDetails).length > 0 && (
                    <>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Full Name
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>
                            {personalDetails.firstName} {personalDetails.lastName}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Mobile Number
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{personalDetails.phone}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Email Address
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{personalDetails.email}</strong>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Divider />

                <Grid item xs={12} container spacing={1}>
                  {!Object.keys(vehicleSpecs).length && (
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textSecondary">
                        No vehicle information available
                      </Typography>
                    </Grid>
                  )}
                  {Object.keys(vehicleSpecs).length > 0 && (
                    <>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Make
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.make}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Model
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.model}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Year
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.year}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Variant
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.variant}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Odometer Reading
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.odometer}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Color
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.color}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          VIN Number
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.vin}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Registration Number
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.regNo}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Transmission
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.transmission}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Fuel Type
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.fuelType}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Spare Keys
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.spareKeys ? "Yes" : "No"}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Service Book
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.serviceBook ? "Yes" : "No"}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Maintenance Plan
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.maintenancePlan ? "Yes" : "No"}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className="pb-5">
                        <Typography variant="caption" color="textSecondary" component="p">
                          Warranty
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{vehicleSpecs.warranty ? "Yes" : "No"}</strong>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Divider />

                <Grid item xs={12} container spacing={1}>
                  {!Object.keys(vehicleImages).length && (
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textSecondary">
                        No vehicle photos available
                      </Typography>
                    </Grid>
                  )}
                  {Object.keys(vehicleImages).length > 0 && (
                    <Grid item xs={12} container spacing={2} justifyContent="space-between" direction="row">
                      {Object.keys(vehicleImages).map((key) => (
                        <Grid item xs={12} sm={6} md={4}>
                          {/* <ImageContainer aspectRatio={(16 / 10)} height='100%' src={vehicleImages[key].toString()} alt={`Vehicle ${key} image`} /> */}
                          <img alt={`Vehicle ${key} image`} src={vehicleImages[key]} className={classes.img} />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>

                <Divider />

                <Grid item xs={12} container justifyContent="flex-end">
                  <Button className={classes.btn} variant="contained" onClick={confirmDetails}>
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default ConfirmationContainer;
