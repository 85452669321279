import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Checkbox,
  Container
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from "yup";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import ImageUploaderInput from "../ImageUploader/ImageUploaderInput";


const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
        //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
      //@ts-ignore
    boxShadow:({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
       //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,

  },
  flexHolder: {
    display: "flex",
    flexWrap: "wrap",
  },
  btn: {
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
  legend: {},
  labels: {},
  radioButton: {},
}));

interface Props {
  base_uri: string | null;
  wePay4Cars_uri?: string | null;
  vehicle: any;
  onSubmit: Function;
  websiteColors?: any;
  licenseDiskScanner?: boolean ;
  filterCat?: boolean ;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
}

const VehicleSpecsForm: FC<Props> = ({ 
  base_uri, 
  licenseDiskScanner,
  filterCat,
  wePay4Cars_uri, 
  vehicle, 
  onSubmit, 
  websiteColors,
  paper,   
  paperColor,
  paperShadowColor, 
}) => {
  
  const classes = useStyles({ ...websiteColors, paper, paperColor, paperShadowColor });
  const localStyles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    licenseDisk: vehicle?.licenseDisk || "",
    category: vehicle?.category || "",
    categoryId: vehicle?.categoryId || -1,
    make: vehicle?.make || "",
    makeId: vehicle?.makeId || 0,
    model: vehicle?.model || "",
    modelId: vehicle?.modelId || 0,
    variant: vehicle?.variant || "",
    variantId: vehicle?.variantId || 0,
    variantCode: vehicle?.variantCode || "",
    variantStartYear: vehicle?.variantStartYear || 0,
    variantEndYear: vehicle?.variantEndYear || 0,
    year: vehicle?.year || "",
    odometer: vehicle?.odometer || "",
    color: vehicle?.color || "",
    vin: vehicle?.vin || "",
    regNo: vehicle?.regNo || "",
    transmission: vehicle?.transmission || "",
    fuelType: vehicle?.fuelType || "",
    spareKeys: vehicle?.spareKeys || false,
    serviceBook: vehicle?.serviceBook || false,
    maintenancePlan: vehicle?.maintenancePlan || false,
    warranty: vehicle?.warranty || false,
  };

  const [loading, setLoading] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [categories, setCategories] = useState<any>([]);
  const [makes, setMakes] = useState<any>([]);
  const [models, setModels] = useState<any>([]);
  const [years, setYears] = useState<any>([]);
  const [variants, setVariants] = useState<any>([]);

  const validationSchema = Yup.object({
    make: Yup.string().required("Select a vehicle make"),
    model: Yup.string().required("Select a vehicle model"),
    variant: Yup.string().required("Select a model variant"),
    // year: Yup.number()
    //   .min(variantIntroYear, "Model year must be between variant introduction and discontinue years")
    //   .max(variantDiscYear, "Model year must be between variant introduction and discontinue years")
    //   .required("Model year is required"),
    odometer: Yup.number().required("Odometer reading is required"),
    regNo: Yup.string().required("Registration number is required"),
    transmission: Yup.string().required("Select a transmission"),
    fuelType: Yup.string().required("Select a fuel type"),
  });

  useEffect(() => {

    let errors: string[] = [];
    let allPromises: Promise<boolean>[] = [];

    allPromises.push(new Promise<boolean>((res) => {
      
      try {
        getCategories();
      } catch (error) {
        errors.push(`Failed to load the vehicle categories: ${error}`);
      }
      
      res(true);

    }));

    if (vehicle?.categoryId > 0) {
      allPromises.push(new Promise<boolean>((res) => {

        try {
          getMakes(vehicle.categoryId);
        } catch (error) {
          errors.push(`Failed to load the makes: ${error}`);
        }
        
        res(true);

      }));
    }

    if (vehicle?.makeId > 0) {
      allPromises.push(new Promise<boolean>((res) => {

        try {
          getModels(vehicle.makeId);
        } catch (error) {
          errors.push(`Failed to load the models: ${error}`);
        }
        
        res(true);

      }));
    }
    
    if (vehicle?.modelId > 0) {
      allPromises.push(new Promise<boolean>((res) => {
        
        try {
          getYears(vehicle.modelId);
        } catch (error) {
          errors.push(`Failed to load the years: ${error}`);
        }
        
        res(true);

      }));
    }

    if (vehicle?.modelId > 0 && vehicle?.year?.length > 0) {
      allPromises.push(new Promise<boolean>((res) => {
        
        try {
          getVariants(vehicle.modelId, vehicle.year);
        } catch (error) {
          errors.push(`Failed to load the variants: ${error}`);
        }
        
        res(true);

      }));
    }

    Promise.allSettled(allPromises)
      .then(() => {
        
        setLoading(false);

        if (errors.length > 0) {
          enqueueSnackbar(`Some errors occurred while loading the form data: ${errors.map(e => e)}`, { variant: 'error' });	
        }
        
      }).catch((error) => {
        
        setLoading(false);
        enqueueSnackbar(`An unexpected error occurred while loading the form data: ${error}`, { variant: 'error' });

      });

  }, []);

  const getCategories = async () => {
    setLoadingOptions(true);

    axios
      .get(`${wePay4Cars_uri}/vehicle/type`)
      .then((result) => {
        setLoadingOptions(false);
        setCategories(result?.data?.sort((a: any, b: any) => a.typeName > b.typeName ? 1 : -1) || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the makes: ${error}`, { variant: "error" });
      });
  };

  const getMakes = async (categoryId: number) => {
    setLoadingOptions(true);

    axios // @ts-ignore
      .get(`${wePay4Cars_uri}/vehicle/Make`, {
        params: {
          vehicleTypeId: categoryId,
        }
      })
      .then((result) => {
        setLoadingOptions(false);
        setMakes(result?.data?.sort((a: any, b: any) => a.manufacturerName > b.manufacturerName ? 1 : -1) || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the makes: ${error}`, { variant: "error" });
      });
  };

  const getModels = async (makeId: number) => {
    setLoadingOptions(true);

    axios // @ts-ignore
      .get(`${wePay4Cars_uri}/vehicle/Model`, {
        params: {
          vehicleMakeId: makeId,
        }
      })
      .then((result) => {
        setLoadingOptions(false);
        setModels(result?.data?.sort((a: any, b: any) => a.modelName > b.modelName ? 1 : -1) || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the models: ${error}`, { variant: "error" });
      });
  };

  const getYears = async (modelId: number) => {
    setLoadingOptions(true);

    fetchYears(modelId)
      .then((result) => {
        setLoadingOptions(false);
        setYears(result?.data || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the variants: ${error}`, { variant: "error" });
      });
  };

  const getVariants = async (modelId: number, year: string) => {
    setLoadingOptions(true);

    fetchVariants(modelId, year)
      .then((result) => {
        setLoadingOptions(false);
        setVariants(result?.data || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(`Failed to load the variants: ${error}`, { variant: "error" });
      });
  };

  const fetchYears = (modelId: number) => {
    // @ts-ignore
    return axios.get(`${wePay4Cars_uri}/vehicle/year`, {
      params: {
        modelId
      }
    });
  };

  const fetchVariants = (modelId: number, year: string) => {
    // @ts-ignore
    return axios.get(`${wePay4Cars_uri}/Vehicle/variant`, {
      params: {
        modelId: modelId,
        year: year,
      }
    });
  };

  const checkLicense = (base64VehicleLicense: string, values: any, setFieldValue: Function) => {
    if (!wePay4Cars_uri) {
      return;
    }

    setLoading(true);

    axios
      .post(
        `${wePay4Cars_uri}/vehicle/contactable`,
        { base64VehicleLicense }
      )
      .then((response: any) => {
        
        if (!response?.data) {
          setLoading(false);
          enqueueSnackbar(`No information retrieved from reading the license disk`, { variant: "warning" });
          return;
        }

        setFieldValue("color", response?.data?.color || values.color);
        setFieldValue("regNo", response?.data?.registration || values.regNo);
        setFieldValue("vin", response?.data?.vin || values.vin);

        axios
          // @ts-ignore
          .get(`${wePay4Cars_uri}/vehicle/modelByName`, {
            params: {
              modelName: response?.data?.model,
            }
          })
          .then((result) => {
            let category = categories.find((c: any) => c.id == result?.data?.category);
            let make = {
              id: result?.data?.manufacturer?.id,
              manufacturerName: result?.data?.manufacturer?.name
            };
            let model = {
              id: result?.data?.id,
              modelName: result?.data?.name
            };

            if (!make.manufacturerName || !model.modelName) {
              setLoading(false);
              enqueueSnackbar(`Could not determine the make or model from the license disk`, { variant: "warning" });
              return;
            }

            setMakes([make]);
            setModels([model]);
            setFieldValue("category", category.typeName);
            setFieldValue("categoryId", category.id);
            setFieldValue("make", make.manufacturerName);
            setFieldValue("makeId", make.id);
            setFieldValue("model", model.modelName);
            setFieldValue("modelId", model.id);

            getYears(model.id)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));

          })
          .catch((error) => {
            setLoading(false);
            enqueueSnackbar(`Failed to get all the models: ${error}`, { variant: "warning" });
          });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(`Failed to read the license disk: ${error}`, { variant: "warning" });
      });
  };

  const handleSelectChange = (e: any, value: any, setFieldValue: Function, fieldName: string, fieldId: string) => {
    setFieldValue(fieldName, e?.target?.value);
    setFieldValue(fieldId, value?.props?.id);
  };

  const handleResetCustom = (setFieldValue: Function) => {
    setFieldValue("category", "");
    setFieldValue("categoryId", -1);
    setFieldValue("make", "");
    setFieldValue("makeId", 0);
    setFieldValue("model", "");
    setFieldValue("modelId", 0);
    setFieldValue("variant", "");
    setFieldValue("variantId", 0);
    setFieldValue("variantCode", "");
    setFieldValue("variantStartYear", 0);
    setFieldValue("variantEndYear", 0);
    setFieldValue("year", "");
    setFieldValue("odometer", "");
    setFieldValue("color", "");
    setFieldValue("vin", "");
    setFieldValue("regNo", "");
    setFieldValue("transmission", "");
    setFieldValue("fuelType", "");
    setFieldValue("spareKeys", false);
    setFieldValue("serviceBook", false);
    setFieldValue("maintenancePlan", false);
    setFieldValue("warranty", false);
  };

  const submitForm = (values: any) => {
    onSubmit(values);
  };

  return (
    <React.Fragment>
      <div className={classes.holder}>
        <CardHeader className="p-4" title={"About your vehicle"} />

        <div className={classes.holderInner}>
          <Formik
            isInitialValid={false}
            initialValues={initialFormValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {(props) => {
              const { 
                values, 
                touched, 
                errors, 
                isValid, 
                handleReset,
                handleBlur, 
                handleChange, 
                setFieldValue 
              } = props;
              return (
                <Form>
                  <Grid container spacing={4} direction="row">

{licenseDiskScanner && 


                    <Grid item xs={12} container justifyContent="flex-start">
                      <ImageUploaderInput
                        title="South African License Disk"
                        fieldName={"licenseDisk"}
                        onChange={(uri: string) => {
                          handleResetCustom(setFieldValue);
                          checkLicense(uri, values, setFieldValue);
                        }}
                        error={errors.licenseDisk && touched.licenseDisk}
                      />
                    </Grid>
                  }
                    {
                      loading 
                      &&
                      <Grid container justifyContent="center">
                        <CircularProgress />
                      </Grid>
                    }
                    {
                      !loading &&
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="category"
                            label="Category"
                            variant="outlined"
                            fullWidth
                            select
                            disabled={!values?.categoryId && loadingOptions}
                            value={values.category}
                            helperText={<>{errors.category && touched.category ? errors.category : ""}</>}
                            error={touched.category && Boolean(errors.category)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "category", "categoryId");

                              // Load the makes for the selected category
                              setTimeout(() => {
                                getMakes(child?.props?.id);
                              }, 100);
                            }}
                          >
                          
                            {filterCat ? 
                            categories.filter((o: any)=> o.typeName !== "Motorcycle").map((option: any) => (
                             
                              <MenuItem key={option.id} id={option.id} value={option.typeName}>
                                {option.typeName}
                              </MenuItem>
                            ))
                          :categories.map((option: any) => (
                             
                            <MenuItem key={option.id} id={option.id} value={option.typeName}>
                              {option.typeName}
                            </MenuItem>
                          ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="make"
                            label="Make"
                            variant="outlined"
                            fullWidth
                            required
                            select
                            disabled={(!values?.makeId && loadingOptions) || values.categoryId < 0}
                            value={values.make}
                            helperText={<>{errors.make && touched.make ? errors.make : ""}</>}
                            error={touched.make && Boolean(errors.make)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "make", "makeId");

                              // Load the models for the selected make
                              setTimeout(() => {
                                getModels(child?.props?.id);
                              }, 100);
                            }}
                          >
                            {makes.map((option: any) => (
                              <MenuItem key={option.id} id={option.id} value={option.manufacturerName}>
                                {option.manufacturerName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="model"
                            label="Model"
                            variant="outlined"
                            fullWidth
                            required
                            select
                            disabled={(!values?.modelId && loadingOptions) || !values?.makeId}
                            value={values.model}
                            helperText={<>{errors.model && touched.model ? errors.model : ""}</>}
                            error={touched.model && Boolean(errors.model)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "model", "modelId");

                              // Load the model years for the selected model
                              setTimeout(() => {
                                getYears(child?.props?.id);
                              }, 100);
                            }}
                          >
                            {models.map((option: any) => (
                              <MenuItem key={option.id} id={option.id} value={option.modelName}>
                                {option.modelName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="year"
                            label="Model Year"
                            variant="outlined"
                            fullWidth
                            select
                            disabled={(!values?.year && loadingOptions) || !values?.modelId}
                            value={values.year}
                            helperText={<>{errors.year && touched.year ? errors.year : ""}</>}
                            error={touched.year && Boolean(errors.year)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "year", "year");

                              // Load the variants for the selected model & year
                              setTimeout(() => {
                                getVariants(values.modelId, child?.props?.value);
                              }, 100);
                            }}
                          >
                            {years.map((option: any) => (
                              <MenuItem key={option} id={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="variant"
                            label="Variant"
                            variant="outlined"
                            fullWidth
                            required
                            select
                            disabled={(!values?.variantId && loadingOptions) || !values?.modelId || !values?.year}
                            value={values.variant}
                            helperText={<>{errors.variant && touched.variant ? errors.variant : ""}</>}
                            error={touched.variant && Boolean(errors.variant)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "variant", "variantId");

                              // Set the variant introduction & discontinue years to validate the Year field
                              let variant = variants.find((v: any) => v.id == child?.props?.id);

                              // Set the field values to be used in the final form
                              setFieldValue("variantCode", variant?.mmcode);
                              setFieldValue("variantStartYear", variant?.startYear);
                              setFieldValue("variantEndYear", variant?.endYear);
                            }}
                          >
                            {variants.map((option: any) => (
                              <MenuItem key={option.id} id={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="odometer"
                            name="odometer"
                            label="Odometer Reading"
                            variant="outlined"
                            required
                            type="number"
                            fullWidth
                            value={values.odometer}
                            helperText={<>{touched.odometer ? errors.odometer : ""}</>}
                            error={touched.odometer && Boolean(errors.odometer)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="color"
                            name="color"
                            label="Color (Optional)"
                            variant="outlined"
                            fullWidth
                            value={values.color}
                            helperText={<>{touched.color ? errors.color : ""}</>}
                            error={touched.color && Boolean(errors.color)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="vin"
                            name="vin"
                            label="VIN Number"
                            variant="outlined"
                           
                            fullWidth
                            value={values.vin}
                            helperText={<>{touched.vin ? errors.vin : ""}</>}
                            error={touched.vin && Boolean(errors.vin)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="regNo"
                            name="regNo"
                            label="Registration Number"
                            variant="outlined"
                            required
                            fullWidth
                            value={values.regNo}
                            helperText={<>{touched.regNo ? errors.regNo : ""}</>}
                            error={touched.regNo && Boolean(errors.regNo)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="transmission"
                            label="Transmission"
                            variant="outlined"
                            fullWidth
                            required
                            select
                            value={values.transmission}
                            helperText={<>{errors.transmission && touched.transmission ? errors.transmission : ""}</>}
                            error={touched.transmission && Boolean(errors.transmission)}
                            onBlur={handleBlur}
                            //@ts-ignore
                            onChange={(e: any, child: any) => {
                              handleSelectChange(e, child, setFieldValue, "transmission", "transmissionId");
                            }}
                          >
                            {["Automatic", "Manual"].map((option: string, index: any) => (
                              <MenuItem key={index} id={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        {/* @ts-ignore */}
                        <Container item xs={12} sm={6} md={4} className={localStyles.flexHolder}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className={localStyles.legend}>
                                Fuel Type *
                              </FormLabel>
                              <RadioGroup
                                aria-label="fuelType"
                                name="fuelType"
                             
                                value={values.fuelType}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="Petrol"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Petrol"
                                />
                                <FormControlLabel
                                  value="Diesel"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Diesel"
                                />
                                <FormControlLabel
                                  value="Hybrid"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Hybrid"
                                />
                                <FormControlLabel
                                  value="Electric"
                                  classes={{ label: localStyles.labels }}
                                  control={<Radio classes={{ checked: localStyles.radioButton }} />}
                                  label="Electric"
                                />
                              </RadioGroup>
                              {errors.fuelType && (
                                <FormHelperText className="mt-0 mb-2" error>
                                  Fuel Type is required
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend" className={localStyles.legend}>
                                Miscellaneous
                              </FormLabel>
                              <FormControlLabel
                                label="Spare Keys"
                                control={
                                  <Checkbox
                                    id="spareKeys"
                                    value={values.spareKeys}
                                    defaultChecked={values.spareKeys}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Service Book"
                                control={
                                  <Checkbox
                                    id="serviceBook"
                                    value={values.serviceBook}
                                    defaultChecked={values.serviceBook}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Maintenance Plan"
                                control={
                                  <Checkbox
                                    id="maintenancePlan"
                                    value={values.maintenancePlan}
                                    defaultChecked={values.maintenancePlan}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Warranty"
                                control={
                                  <Checkbox
                                    id="warranty"
                                    value={values.warranty}
                                    defaultChecked={values.warranty}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Container>
                      </>
                    }

                    <Grid item xs={12} container justify="flex-end">
                      <Button className={classes.btn} variant="contained" color="primary" type="submit">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VehicleSpecsForm;
