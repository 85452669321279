/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { FC, useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { Switch } from "formik-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";
import { CircularProgress, MenuItem } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { GAEvent } from "../GoogleAnalytics";
//@ts-ignore
import { ReactNode } from "react-transition-group/node_modules/@types/react";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
    //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
    //@ts-ignore
    boxShadow: ({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
    //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,
  },
  dividerTitle: {
    backgroundColor: "#06141f",
    width: "33%",
    marginBottom: "0px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  url: {
    color: "#7c7c7c",
    textDecoration: "none",
    "&:hover": {
      color: "#1f2532",
      textDecoration: "none",
    },
  },
  detailsParagraph: {
    textAlign: "left",
    fontSize: "1.8rem",
  },
  link: {
    backgroundColor: "transparent",
    marginBottom: "1rem",
    /* @ts-ignore */
    color: ({ primaryColor }) => `${primaryColor}!important`,
    "&:hover": {
      textDecoration: "none",
      opacity: 0.8,
    },
  },
  typoHeading: {
    textAlign: "center",
    color: "textPrimary",
    fontWeight: 500,
  },
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  button: {
    width: "100%",
    //@ts-ignore
    backgroundColor: ({ websiteColors }) => `${websiteColors?.accentColor || 'grey'}`,
    //@ts-ignore
    color: ({ websiteColors }) => `${websiteColors?.primaryColor || 'black'}`,
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: "uppercase",
  },
  noSideDetails: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface Props {
  base_uri: string;
  dealerId: number;
  activeDealerId?: number;
  masterDealerId?: number;
  dealerList?: Dealer[];
  vin?: string;
  title?: string;
  variant: "standard";
  sideBarDetailsHeader?: string;
  sideBarDetails?: ReactNode;
  isCashBuyer?: string | null;
  variantId?: number;
  leadTypeId?: number;
  dealerName: string;
  questions?: any[];
  websiteColors?: any;
  buttonBoldFont?: boolean;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
}

interface Dealer {
  dealerId: any;
  name: any;
}

interface Question {
  name: string;
  labelComponent: any;
  correct: boolean;
  order: number;
  answer?: boolean;
}

interface LeadInput {
  leadStatusId?: number;
  dealerId?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  leadSourceId?: number;
  TypeCode?: string;
  notes?: string;
  leadTypeId?: number;
  dateUpdated?: string;
}

interface LeadVehicleInput {
  LeadId?: number;
  VariantId?: number;
}

const Parts: FC<Props> = ({
  base_uri,
  dealerName,
  dealerId,
  dealerList,
  vin,
  activeDealerId,
  masterDealerId,
  websiteColors,
  questions,
  title,
  isCashBuyer,
  variantId,
  buttonBoldFont = true,
  paper,
  paperColor,
  paperShadowColor,
}) => {
  const classes = useStyles({ ...websiteColors, paper, paperColor, paperShadowColor });

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'grey',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'grey',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'black',
        },
      },
    },
  })(TextField);

  const defaultQuestions = [
    {
      name: "contactMe",
      labelComponent: dealerName ? <span>I allow {dealerName} to contact me</span> : <span>I allow the dealership to contact me</span>,
      correct: true,
      order: 1,
    },
    {
      name: "popi",
      labelComponent: (
        <span>
          By submitting this form I agree to the{" "}
          <Link component={RouterLink} to="/terms" className={[classes.link, buttonBoldFont ? classes.bold : {}].join(' ')}>
            terms and conditions
          </Link>{" "}
          and{" "}
          <Link component={RouterLink} to="/privacy" className={[classes.link, buttonBoldFont ? classes.bold : {}].join(' ')}>
            privacy policies.
          </Link>
        </span>
      ),
      correct: true,
      order: 4,
    },
  ];

  if (!questions) {
    questions = defaultQuestions;
  }

  const [questionAnswers, setQuestionAnswers] = useState<Question[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [delearName, setDelearName] = useState(undefined);
  const [delearDetails, setDelearDetails] = useState<any>(undefined);

  useEffect(() => {
    let f = dealerList?.filter((d) => d.dealerId !== masterDealerId);
    setDelearName(f?.filter((d) => d.dealerId == activeDealerId)[0]?.name);

    if (dealerId !== masterDealerId) {
      setDelearDetails(dealerList?.filter((d) => d.dealerId == activeDealerId)[0]);
    }
  }, [dealerList, activeDealerId, dealerId]);

  let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const values = {
    name: "",
    phone: "",
    email: "",
    dealerId: delearDetails?.dealerId || activeDealerId || dealerId,
    dealer: delearDetails?.name || delearName || "",
    notes: "",
    vin: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
  });

  const checkValid = (validForm: boolean) => {
    var validAnswers = true;
    if (questions) {
      for (var idx in questions) {
        var itm = questions[idx];
        // eslint-disable-next-line no-loop-func
        var answer = questions.filter((answer) => answer.name == itm.name)[0];

        if (itm.correct && !answer) {
          validAnswers = false;
        } else if (answer && itm.correct != answer.answer) {
          validAnswers = false;
        }
      }
    }

    return validForm && validAnswers;
  };

  const createVehicleLead = (values: any) => {
    let vehicleParam: LeadVehicleInput = {};
    vehicleParam.LeadId = values?.leadId;
    vehicleParam.VariantId = values?.variantId;
    // @ts-ignore
    axios.post(`${base_uri}/leadVehicles`, vehicleParam)
      .then((responseSecond) => {
        setLoader(false);
        enqueueSnackbar(`Successfully sent information`, { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar("Unable to get complete the request", { variant: "error" });
        //setLoader(false);
      });
  };

  const createLead = (values: any) => {
    setLoader(true);

    const pageTitle = document.getElementsByTagName("title")[0].innerHTML;
    GAEvent('form_submissions', 'form_submissions', 'partsEnquiry_submission', 'xhr',
    {
      url: window.location.pathname,
      page_title: pageTitle,
      form_type: 'partsEnquiry'
    });

    let params: LeadInput = {};
    params.leadStatusId = 1;
    params.dealerId = dealerId;
    params.name = values.name;
    params.firstName = values.name;
    params.lastName = "";
    params.phoneNumber = values.phone;
    params.emailAddress = values.email;
    params.leadSourceId = 1;
    params.TypeCode = "NEW";
    params.notes = isCashBuyer ? `Cash buyer + ${values.notes} ${values.vin}` : `${values.notes} ${values.vin} `
    params.leadTypeId = 6;
    params.dateUpdated = new Date().toISOString();
    // @ts-ignore
    axios.post(`${base_uri}/leads`, params)
      .then((response) => {
        //@ts-ignore
        fbq("track", "Lead"); // eslint-disable-line
        if (variantId) {
          values.leadId = response.data.id;

          createVehicleLead(values);
        } else {
          setLoader(false);
          enqueueSnackbar(`Successfully sent information`, { variant: "success" });
        }
      })
      .catch((error) => {
        // TODO: REMOVE THIS SHIT
        enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
        // enqueueSnackbar("Unable to complete the request", { variant: 'error' });
        setLoader(false);
      });
  }

  const setQuestion = (question: Question) => {
    var answer = false;
    var existingAnswer = questionAnswers.filter((itm: Question) => itm.name === question.name);

    if (existingAnswer && existingAnswer.length > 0) {
      answer = existingAnswer[0].answer!;
    }

    question.answer = answer ? false : true;

    questionAnswers.push(question);
    setQuestionAnswers(questionAnswers);
  }

  const handleSelectChange = (e: any, value: any, setFieldValue: any, fieldName: any, fieldId: any) => {
    setFieldValue(fieldName, e.target.value)
    setFieldValue(fieldId, value.props.id)
  }

  return (
    <React.Fragment>
      {title && (
        <>
          <Typography gutterBottom variant="h5" className={classes.typoHeading}>
            {title}
          </Typography>
          <Divider className={classes.dividerTitle} />
        </>
      )}
      <MuiThemeProvider>
        <Grid container direction="row">
          <Grid item xs={12}>
            <div className={classes.holder}>
              <div className={classes.holderInner}>
                <Formik
                  enableReinitialize={true}
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    createLead(values)
                  }}
                >
                  {(props: any) => {
                    const {
                      values,
                      touched,
                      errors,
                      submitForm,
                      isValid,
                      setFieldTouched,
                      setFieldValue,
                    } = props;
                    return (
                      <Form>
                        <Grid container direction="row">
                          <Grid item xs={12} md={12}>
                            <CssTextField
                              id="name"
                              name="name"
                              label="Name"
                              value={values.name}
                              fullWidth
                              variant="outlined"
                              helperText={touched.name ? errors.name : ""}
                              error={touched.name && Boolean(errors.name)}
                              onChange={(event) => {
                                setFieldValue("name", event.target.value);
                                setFieldTouched("name", true, false);
                              }}
                            />
                          </Grid>

                          <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                            <CssTextField
                              id="email"
                              name="email"
                              type="email"
                              label="Email"
                              fullWidth
                              value={values.email}
                              variant="outlined"
                              helperText={touched.email ? errors.email : ""}
                              error={touched.email && Boolean(errors.email)}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value);
                                setFieldTouched("email", true, false);
                              }}
                            />
                          </Grid>

                          <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                            <CssTextField
                              id=" phone"
                              name="phone"
                              type="tel"
                              label="Cellphone"
                              fullWidth
                              value={values.phone}
                              variant="outlined"
                              helperText={touched.phone ? errors.phone : ""}
                              error={touched.phone && Boolean(errors.phone)}
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value);
                                setFieldTouched("phone", true, false);
                              }}
                            />
                          </Grid>

                          {vin &&
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                              <CssTextField
                                id=" vin"
                                name="vin"
                                type="text"
                                label="Vin number"
                                fullWidth
                                value={values.vin}
                                variant="outlined"
                                helperText={touched.vin ? errors.vin : ""}
                                error={touched.vin && Boolean(errors.vin)}
                                onChange={(event) => {
                                  setFieldValue("vin", event.target.value);
                                  setFieldTouched("vin", true, false);
                                }}
                              />
                            </Grid>
                          }

                          <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                            <CssTextField
                              variant="outlined"
                              fullWidth
                              id="dealer"
                              select={
                                delearName !== undefined
                                  ? false
                                  : true && delearDetails !== undefined
                                    ? false
                                    : true
                              }
                              //required
                              label="Dealership"
                              value={values.dealer}
                              helperText={errors.dealer && touched.dealer ? errors.dealer : ''}
                              /* @ts-ignore */
                              error={errors.dealer && touched.dealer}

                              /* @ts-ignore */
                              onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealer', 'dealerId')}
                            >
                              {dealerList && dealerList.map((option: any) => (
                                <MenuItem key={option.id} id={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </CssTextField>
                          </Grid>

                          <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                            <Box mb={2}>
                              <CssTextField
                                id="notes"
                                name="notes"
                                placeholder="leave a message"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                variant="standard"
                                helperText={touched.notes ? errors.notes : ""}
                                error={touched.notes && Boolean(errors.notes)}
                                onChange={(event) => {
                                  setFieldValue("notes", event.target.value)
                                  setFieldTouched("notes", true, false);
                                }}
                              />
                            </Box>
                          </Grid>

                          {questions &&
                            questions.map((itm: Question) => (
                              <Grid item className={classes.flex} xs={12} md={12} lg={12} style={{ alignItems: 'center' }}>
                                {/* @ts-ignore */}
                                <FormControlLabel
                                  control={
                                    <Field
                                      component={Switch}
                                      color="primary"
                                      type="checkbox"
                                      name={itm.name}
                                      onClick={() => {
                                        setQuestion(itm);
                                      }}
                                    />
                                  }
                                />
                                {itm.labelComponent}
                              </Grid>
                            ))}

                          <Grid container direction="row">
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                              {!loader && (
                                <Button
                                  className={classes.button}
                                  variant={"contained"}
                                  color="primary"
                                  disabled={!checkValid(isValid)}
                                  onClick={() => {
                                    submitForm();
                                  }}
                                >
                                  {isCashBuyer ? "Order Now" : "Contact Me"}
                                </Button>
                              )}
                              {loader == true && (
                                <Button
                                  className={classes.button}
                                  variant={"contained"}
                                  color="primary"
                                  disabled={true}
                                >
                                  <CircularProgress />
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default Parts;
