import React, { createContext, useState } from 'react';
import { MetaTagContextType, IMetaTag } from '../types/metatag';

export const MetaTagsContext = createContext<MetaTagContextType>({ tags: {}, updateTags: (newTags: IMetaTag) => { } });

interface Props {
  children: React.ReactNode;
}

const MetaTagsProvider: React.FC<Props> = ({ children }) => {
  const [tags, setTags] = useState<IMetaTag>({});

  const updateTags = (newTags: IMetaTag) => {
    setTags(newTags);
  };

  return (
    <MetaTagsContext.Provider value={{ tags, updateTags }}>
      {children}
    </MetaTagsContext.Provider>
  );
};

export default MetaTagsProvider;
