import React, { FC, useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Button,
    Typography,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    IconButton,
    InputBase,
    Chip,
    Paper,
    Collapse,
    ClickAwayListener,
} from "@material-ui/core";

import {
    ToggleButtonGroup,
    ToggleButton,
} from "@material-ui/lab";

import {
    ExpandLess,
    ExpandMore,
    ArrowDropDown,
    ArrowDropUp,
} from "@material-ui/icons";

import { isNullOrUndefined } from "util";

import CircularProgress from "@material-ui/core/CircularProgress";
import { RangeSlider } from "./RangeSlider";

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 700,
    },
    flexCard: {
        display: "flex",
        "@media (max-width: 900px)": {
            flexDirection: "column",
        },
    },
    main: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "end",
        "@media (max-width: 900px)": {
            display: "flex",
            flexDirection: "column !important",
        },
    },
    orientColumn: {
        flexDirection: "column",
    },
    orientRow: {
        flexDirection: "row",
    },
    btn2Active: {
        //@ts-ignore
        // minWidth: ({ orientation }) => `${orientation === "row" ? "95%" : "100%"}`,
        width: "100%",
        // margin: "10px 0px",
        //@ts-ignore
        color: ({ btnText }) => `${btnText ? btnText : "black"} !important`,
         //@ts-ignore
        backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
        "@media (max-width: 900px)": {
            // minWidth: "100%",
            // margin: "10px 0px",
        },
        "&:hover": {
            //@ts-ignore
            backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
            opacity: 0.8,
        },
    },
    btn2ActiveClear: {
        background: "red!important",
        // margin: "10px 0px",
        //@ts-ignore
        // minWidth: ({ orientation }) => `${orientation === "row" ? "95%" : "100%"}`,
        width: "100%",

        "@media (max-width: 900px)": {
            // minWidth: "100%",
            // margin: "10px 0px",
        },
    },
    btn2Inactive: {
        //@ts-ignore
        // minWidth: ({ orientation }) => `${orientation === "row" ? "95%" : "100%"}`,
        width: "100%",
        // margin: "10px 0px",
        //@ts-ignore
        backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
        opacity: "0.5",
        "@media (max-width: 900px)": {
            // minWidth: "100%",
            // margin: "10px 0px",
        },
    },
    content: {
        backgroundColor: theme.palette.background.paper,
    },
    headingHolder: {
        backgroundColor: "#06141f",
        textAlign: "center",
        marginBottom: "0px",
        color: "white",
        margin: "30px auto",
    },
    img: {
        width: "100%",
        height: "fit-content",
    },
    typoheading: {
        textAlign: "center",
        color: "textPrimary",
        fontWeight: 500,
    },
    search: {
        backgroundColor: "#f9f9f9",
        border: "none",
        borderRadius: "0px",
        padding: "10px",
        color: "black",
        width: "100%",
        "&:focus": {
            outline: "none",
            border: "none",
        },
    },
    searchHolder: {
        //@ts-ignore
        width: ({ orientation }) => `${orientation === "row" ? "25%" : "100%"}`,
        display: "flex",
        marginBottom: "10px",
        justifyContent: "end",
        "@media (max-width: 900px)": {
            width: "100%!important",
        },
    },
    filterApplied: {
        paddingBottom: "0.5rem",
    },
    appliedFilterText: {
        display: "inline-block",
        fontSize: "1rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    clearFilter: {
        textAlign: "right",
        cursor: "pointer",
        float: "right",
        color: "#ff2424",
    },
    appliedFilter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1.15rem",
        margin: "5px",
    },
    marginSpacing: {
        width: "95%!important",
        "@media (max-width: 900px)": {
            width: "100%!important",
        },
    },
    flexHolder: {
        display: "flex",
        justifyContent: "end",
    },
    input: {
        margin: "5px 0px",
        //@ts-ignore
        color: ({ InputColor }) =>
            `${InputColor ? InputColor : "black"} !important`,
    },
    btnHolder: {
        display: "flex",
        justifyContent: "end",
    },
    inputlabel: {
        //@ts-ignore
        color: ({ labelColor }) =>
            `${labelColor ? labelColor : "black"} !important`,
    },
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    vehicleList: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    searchMainContainer: {
        width: "100%",
        height: "fit-Content",
        background: "white",
        fontSize: "14px",
        border: "1px solid rgba(27, 31, 35, 0.2)",
        borderRadius: "4px",
        marginBottom: "12px",
        //@ts-ignore
        border: ({ borderColor }) => ` 1px solid  ${borderColor ? borderColor : "black"}  !important`,
        "&& svg ":{
//@ts-ignore
          color: ({ checkColor }) => ` ${checkColor ? checkColor : "black"}  !important`,
        }
    },
    searchVehiclesTree: {
        width: "100%",
        height: "fit-Content",
        maxHeight: "30vh",
        overflowY: "auto",
    },
    selectedItemsContainer: {
        height: "fit-content",
        display: "flex",
        justifyContent: "start",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
        boxShadow: "unset",


    },
    chip: {
        margin: theme.spacing(0.5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    searchInput: {
        minWidth: "100px",
        width: "100%",
        paddingLeft: "10px",
        border: "none",
        //@ts-ignore
        color: ({ InputColor }) =>
            `${InputColor ? InputColor : "black"} !important`,
        "&:focus": {
            outline: "none",
            border: "none",
        },
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectLabel: {
        padding: "2px",
        //@ts-ignore
        color: ({ labelColor }) =>
            `${labelColor ? labelColor : "black"} !important`,
        //@ts-ignore
        background: ({ background }) =>
            `${background ? background : "white"} !important`,
        zIndex: 10,
        marginLeft: "4px",
        minWidth: "fit-content",
    },
    filterComponent: {

    },
    toggles: {
       
        "& .MuiToggleButton-root.Mui-selected": {
            color: "#000000",
            backgroundColor: theme.palette.primary.main,
            BorderColor: theme.palette.primary.main,
        },
    },
    stockNoSearchContainer: {
        width: "100%",
        marginBottom: "12px",
        display: "flex",
        justifyContent: "flex-end",
        "@media (max-width: 500px)": {
            justifyContent: "center",
        },
    },
    stockNoSearchField: {
        padding: "5px 5px 5px 10px",
       
        border: "1px solid rgba(27, 31, 35, 0.2)",
        borderRadius: "4px",
         //@ts-ignore
        border: ({ borderColor }) => ` 1px solid  ${borderColor ? borderColor : "black"}  !important`,
        //@ts-ignore
        color: ({ InputColor }) =>
            `${InputColor ? InputColor : "black"} !important`,
        "&:focus": {
            outline: "none",
            border: "none",
        },
    },
    toggleBtn:{
      //@ts-ignore
      border: ({ borderColor }) => ` 1px solid  ${borderColor ? borderColor : "black"}  !important`,
      //@ts-ignore
      color: ({ labelColor }) => ` ${labelColor ? labelColor : "black"}  !important`,
    },
    checkBox:{
  "&& svg ":{
//@ts-ignore
    color: ({ checkColor }) => ` ${checkColor ? checkColor : "black"}  !important`,
  }
    }

}));

interface IVehicle {
    bodyType: string | undefined;
    brand: string;
    category: string | undefined;
    cellNumber: string | undefined;
    colour: string | undefined;
    comments: string | undefined;
    contactPerson: string | undefined;
    dealerId: number | undefined;
    dealershipName: string | undefined;
    disclaimer: string | undefined;
    dtLastService: string | undefined;
    dtMaintenancePlanExpiry: string | undefined;
    dtNextService: string | undefined;
    dtServicePlanExpiry: string | undefined;
    dtWarrantyExpiry: string | undefined;
    dtWarrantyInception: string | undefined;
    email: string | undefined;
    features: string | undefined;
    foreignDealerId: string | undefined;
    foreignId: string | undefined;
    fsh: boolean | undefined;
    fuelType: string | undefined;
    image: string | undefined;
    lastServiceDoneBy: number | undefined;
    lastServiceKms: number | undefined;
    mainUrls: string | undefined;
    maintenancePlanValid: boolean | undefined;
    mileage: string | undefined;
    mmCode: string | undefined;
    model: string;
    modelRange: string | undefined;
    movURL: string | undefined;
    newUsed: string | undefined;
    newUsedType: string | undefined;
    nextServiceKms: number | undefined;
    price: number;
    region: string | undefined;
    serviceBooksProvided: boolean | undefined;
    serviceHistory: boolean | undefined;
    servicePlanValid: boolean | undefined;
    stockId: number | undefined;
    stockNo: string | undefined;
    thumbUrls: string | undefined;
    transmission: string | undefined;
    twoKeys: boolean | undefined;
    vin: string | undefined;
    warrantyActive: boolean | undefined;
    warrantyDescription: string | undefined;
    warrantyDistance: number | undefined;
    year: number;
    province: string;
}

interface IProps {
    pageSize?: number;
    websiteColors?: any;
    orientation?: "column" | "row";
    updateFilteredVehicles(updatevehicles: any[]): any;
    vehicles: IVehicle[];
    yearRangeInterval?: number;
    priceRangeInterval?: number;
    instalmentRangeInterval?: number;
}
/* 
interface IChildNode {
  id: string;
  name: string;
  displayName: string;
  isSelected: boolean;
  children: IChildNode[] | null;
} */

interface INode {
    id: string;
    name: string;
    stockNos: string[];
    displayName: string;
    isSelected: boolean;
    children: INode[] | null;
    expanded: boolean;
    parentId: string | null;
    isChildSelected: boolean | false;
}

interface RangeCfg {
    interval: number;
    start: number;
    end: number;
}
const VehiclesFilterV2: FC<IProps> = ({
    pageSize = 20,
    websiteColors,
    updateFilteredVehicles,
    vehicles = [],
    orientation = "row",
    yearRangeInterval = 1,
    priceRangeInterval = 50000,
    instalmentRangeInterval = 1500,
}) => {

    const classes = useStyles({ ...websiteColors, orientation });

    const getInstallmentEstimate = (price: number): number => {
        return parseInt(Math.round(price / 60).toFixed(0));
    };

    const [yearRange, setYearRange] = useState<RangeCfg>();
    const [priceRange, setPriceRange] = useState<RangeCfg>();
    const [instalmentRange, setInstalmentRange] = useState<RangeCfg>();

    const [selectedYearRange, setSelectedYearRange] = useState<number[]>([0, 0]);
    const [selectedPriceRange, setSelectedPriceRange] = useState<number[]>([0, 0,]);
    const [selectedInstalmentRange, setSelectedInstalmentRange] = useState<number[]>([0, 0]);

    const setRanges = () => {

        const yearStart = Math.floor(
            Math.min(...vehicles.filter(v => v.year > 0).map((o) => o.year)) / yearRangeInterval
        ) * yearRangeInterval;
        const yearEnd = Math.ceil(
            Math.max(...vehicles.map((o) => o.year)) / yearRangeInterval
        ) * yearRangeInterval;
        const year = {
            interval: yearRangeInterval,
            start: isFinite(yearStart) ? yearStart : 0,
            end: isFinite(yearEnd) ? yearEnd : 0,
        };

        setYearRange(year);
        setSelectedYearRange([year.start, year.end]);

        const priceStart = Math.floor(
            Math.min(...vehicles.map((o) => o.price)) / priceRangeInterval
        ) * priceRangeInterval;
        const priceEnd = Math.ceil(
            Math.max(...vehicles.map((o) => o.price)) / priceRangeInterval
        ) * priceRangeInterval;
        const price = {
            interval: priceRangeInterval,
            start: isFinite(priceStart) ? priceStart : 0,
            end: isFinite(priceEnd) ? priceEnd : 0,
        };

        setPriceRange(price);
        setSelectedPriceRange([price.start, price.end]);

        const instalStart = Math.floor(
            Math.min(...vehicles.map((o) => getInstallmentEstimate(o.price))) /
            instalmentRangeInterval
        ) * instalmentRangeInterval;
        const instalEnd = Math.ceil(
            Math.max(...vehicles.map((o) => getInstallmentEstimate(o.price))) /
            instalmentRangeInterval
        ) * instalmentRangeInterval;
        const instalment = {
            interval: instalmentRangeInterval,
            start: isFinite(instalStart) ? instalStart : 0,
            end: isFinite(instalEnd) ? instalEnd : 0,
        };

        setInstalmentRange(instalment);
        setSelectedInstalmentRange([instalment.start, instalment.end]);
    };

    const transmissionOptions = [...new Set(vehicles.map((o) => o.transmission?.toLowerCase())),].filter((o) => !!o?.trim());
    const fuelTypeOptions = [...new Set(vehicles.map((o) => o.fuelType?.toLowerCase())),].filter((o) => !!o?.trim());

    const initialVehicles = useMemo(
        () =>
            vehicles && vehicles.length > 0
                ? vehicles.map((v) => ({
                    ...v,
                    brand: v?.brand?.toUpperCase(),
                    model: v?.model?.toUpperCase(),
                }))
                : [],
        [vehicles]
    );

    const [filterApplied, setFilterApplied] = useState(false);

    const [selectedTransmission, setSelectedTransmission] = useState<string[]>([]);
    const [selectedFuelType, setSelectedFuelType] = useState<string[]>([]);

    const [vehiclesNodes, setVehiclesNodes] = useState<INode[]>([]);
    const [displayList, setDisplayList] = useState<INode[]>([]);
    const [vehicleSearch, setVehicleSearch] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<INode[]>([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [showVehiclesTree, setShowVehiclesTree] = useState(false);
    const [searchResults, setSearchResults] = useState<IVehicle[]>(initialVehicles);

    useEffect(() => {
        setRanges();
        setDisplayList(vehiclesNodes);
    }, []);

    useEffect(() => {
        if (initialVehicles && initialVehicles.length > 0) {
            updateAndPageVehicles(initialVehicles);
            initialiseVehiclesNodes();
        }
    }, [initialVehicles]);

    const updateAndPageVehicles = (vehiclesList: any[]) => {
        const pagedVehicles = pageVehicles(vehiclesList);
        updateFilteredVehicles(pagedVehicles);
    };

    const filterVehicles = () => {
        setFilterApplied(true);

        let searchFilteredList =
            searchResults?.length > 0 ? [...searchResults] : [...initialVehicles];

        /*  console.log('filterVehicles:: INIT',
          '\n-searchFilteredList', searchFilteredList.map(o=> getInstallmentEstimate(o.price)),
          '\n-initialVehicles', initialVehicles.map(o=> getInstallmentEstimate(o.price)),
          '\n-vehicles', vehicles.map(o=> getInstallmentEstimate(o.price)),
          '\n-selectedItems', selectedItems,
          '\n-selectedYearRange', selectedYearRange,
          '\n-selectedPriceRange', selectedPriceRange,
          '\n-selectedInstalmentRange', selectedInstalmentRange,
          '\n-selectedTransmission', selectedTransmission,
          '\n-selectedFuelType', selectedFuelType,
        );
     */
        searchFilteredList = [...searchFilteredList]
            .filter(
                (o) =>
                    o.price &&
                    o.price >= selectedPriceRange[0] &&
                    o.price <= selectedPriceRange[1]
            )
            .filter(
                (o) =>
                    o.price &&
                    getInstallmentEstimate(o.price) >= selectedInstalmentRange[0] &&
                    getInstallmentEstimate(o.price) <= selectedInstalmentRange[1]
            )
            .filter(
                (o) =>
                    o.year &&
                    o.year >= selectedYearRange[0] &&
                    o.year <= selectedYearRange[1]
            )
            .filter((o) =>
                selectedTransmission.length > 0
                    ? o.transmission &&
                    selectedTransmission?.includes(o.transmission.toLowerCase())
                    : true
            )
            .filter((o) =>
                selectedFuelType.length > 0
                    ? o.fuelType && selectedFuelType?.includes(o.fuelType.toLowerCase())
                    : true
            );

        updateAndPageVehicles(searchFilteredList);
    };

    const clearFilter = () => {
        setSelectedItems([]);
        initialiseVehiclesNodes();

        setSelectedYearRange([yearRange?.start || 0, yearRange?.end || 0]);
        setSelectedPriceRange([priceRange?.start || 0, priceRange?.end || 0]);
        setSelectedInstalmentRange([
            instalmentRange?.start || 0,
            instalmentRange?.end || 0,
        ]);
        setSelectedTransmission([]);
        setSelectedFuelType([]);
        setFilterApplied(false);

        //reset vehicles list
        updateAndPageVehicles(initialVehicles);
        setSearchResults(initialVehicles);
    };

    const pageVehicles = (tmpVehicles: any[]) => {
        var numPages = tmpVehicles?.length / pageSize;
        var tmpPagesData = [];

        for (let i = 0; i < numPages; i++) {
            const index = i * pageSize;
            tmpPagesData.push(tmpVehicles.slice(index, index + pageSize));
        }

        return tmpPagesData;
    };

    const toTitleCase = (str: any) => {
        return str?.replace(/\w\S*/g, function (txt: any) {
            return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    useEffect(() => {
        let searchFilteredList = [...initialVehicles];
        const selectedItemsKeys = Array.from(
            new Set(selectedItems.map((itm) => itm?.id?.toUpperCase()))
        );

        searchFilteredList = searchFilteredList.filter((v) => {
            var modelParts = v?.model?.split(" ");
            var modelShortName = modelParts?.length > 0 ? modelParts[0] : null;

            return (
                (!isNullOrUndefined(v?.brand) &&
                    selectedItemsKeys?.includes(v?.brand?.toUpperCase())) ||
                (!isNullOrUndefined(modelShortName) &&
                    (selectedItemsKeys?.includes(modelShortName?.toUpperCase()) ||
                        selectedItemsKeys?.includes(v?.model?.toLocaleUpperCase())))
            );
        });

        setSearchResults(searchFilteredList);
    }, [selectedItems]);

    useEffect(() => {
        if (vehicleSearch) {
            getDisplayItems(vehiclesNodes);
        } else {
            setDisplayList(vehiclesNodes);
        }
    }, [vehicleSearch, showVehiclesTree]);

    const getDisplayItems = (items: INode[]) => {

        setSearchLoading(true);

        const searchResults = items.filter((item) => {
            return (
                item?.id?.toLowerCase()?.includes(vehicleSearch?.toLowerCase()) ||
                item?.children?.findIndex((itm) =>
                    itm?.name?.toLowerCase()?.includes(vehicleSearch?.toLowerCase()) ||
                    itm?.stockNos?.some(s => s?.toLowerCase().includes(vehicleSearch?.toLowerCase()))
                ) !== -1
            );
        });

        setSearchLoading(false);
        setDisplayList(searchResults);

    };

    const initialiseVehiclesNodes = () => {
        let tmpMakes = [] as INode[];

        const fltMakes = Array.from(
            new Set(initialVehicles.map((v) => toTitleCase(v?.brand)))
        ).sort();

        fltMakes.forEach((make) => {
            const makeModels = Array.from(
                new Set(
                    initialVehicles
                        .filter((v) => {
                            return v?.brand?.toUpperCase() === make?.toUpperCase();
                        })
                        .map((v) => { return { modelName: v?.model, stockNo: v?.stockNo }; })
                )
            ).sort();

            let tmpModelsChildren = [] as INode[];
            var uniqueModels = [] as string[];
            makeModels.forEach((model: any) => {
                var modelParts = model?.modelName?.split(" ");
                var modelShortName = modelParts?.length > 0 ? modelParts[0] : null;

                if (modelShortName) {
                    if (uniqueModels?.indexOf(modelShortName) === -1) {
                        uniqueModels?.push(modelShortName);

                        var childModel: INode = {
                            id: model?.modelName,
                            name: model?.modelName,
                            stockNos: [model?.stockNo],
                            displayName: `${make} ${model?.modelName}`,
                            isSelected: false,
                            expanded: false,
                            isChildSelected: false,
                            parentId: make,
                            children: []
                        };

                        tmpModelsChildren.push(childModel);
                    } else {
                        var tempModelsChild = tmpModelsChildren.find(c => c.name.startsWith(modelShortName));

                        tempModelsChild?.stockNos.push(model?.stockNo);
                    }
                }
            });

            var tmpMake: INode;
            tmpMake = {
                id: make,
                name: make,
                stockNos: [],
                displayName: make,
                isSelected: false,
                expanded: false,
                parentId: null,
                isChildSelected: false,
                children: tmpModelsChildren,
            };
            tmpMakes.push(tmpMake);
        });

        //@ts-ignore
        setVehiclesNodes(tmpMakes);
    };

    const isSelected = (item: INode) => {
        return selectedItems.indexOf(item) !== -1;
    };

    const onSearchVehicle = (value: string) => {
        setVehicleSearch(value);
    };

    const handleSelected = (item: INode, e: any) => {

        e.stopPropagation();

        const selectedListCopy = [...selectedItems];
        var parentHasSelectedChild = false;

        const index = selectedListCopy.indexOf(item);
        if (index === -1) {
            selectedListCopy.push(item);

            //check is selected is parent  then remove all chindren
            if (!isNullOrUndefined(item?.parentId)) {
                const parentIndex = selectedListCopy.findIndex((v) => {
                    return v?.id === item?.parentId;
                });
                if (parentIndex !== -1) {
                    selectedListCopy?.splice(parentIndex, 1);
                }
            }
        } else {
            selectedListCopy?.splice(index, 1);
        }

        //check if item is child
        if (!isNullOrUndefined(item?.parentId)) {
            //check if item's parent still has children in selected list
            const index = selectedListCopy.findIndex((itm) => {
                return (
                    !isNullOrUndefined(itm?.parentId) && itm?.parentId === item?.parentId
                );
            });
            parentHasSelectedChild = index >= 0 ? true : false;
        } else {
            //if parentis selected then clear all child items
            if (!isNullOrUndefined(item?.children)) {
                const parentChildren = [...item?.children];
                parentChildren.forEach((parentChild) => {
                    const parentChildIndex = selectedListCopy.indexOf(parentChild);
                    if (parentChildIndex !== -1) {
                        selectedListCopy.splice(parentChildIndex, 1);
                    }
                });
            }
        }

        setSelectedItems(selectedListCopy);

        const vehicleListCopy = [...vehiclesNodes];
        vehicleListCopy.forEach((listItem) => {
            if (listItem?.id === item?.id) {
                listItem.isSelected = !item?.isSelected;

                if (isNullOrUndefined(item.parentId) && item?.isChildSelected) {
                    listItem.isSelected = true;
                    listItem.isChildSelected = false;
                    if (
                        !isNullOrUndefined(listItem?.children) &&
                        listItem?.children?.length > 0
                    ) {
                        const parentChildren = [...listItem?.children];
                        parentChildren.forEach((child) => {
                            child.isSelected = false;
                        });
                    }
                }
            }

            //update parent
            // item is child and curentListItem is parent  and crrent listItem is child's parent and parent does not have child in selectedList then update parent
            if (
                !isNullOrUndefined(item?.parentId) &&
                isNullOrUndefined(listItem?.parentId) &&
                listItem.id === item?.parentId
            ) {
                if (parentHasSelectedChild) {
                    listItem.isSelected = false;
                }
                listItem.isChildSelected = parentHasSelectedChild ? true : false;
            }

            //ensure child is updated
            if (
                !isNullOrUndefined(listItem?.children) &&
                listItem?.children?.length > 0
            ) {
                const childList = [...listItem?.children];

                childList?.forEach((child) => {
                    if (child?.id === item?.id) {
                        child.isSelected = !item?.isSelected;
                    }
                });
            }
        });
        setVehiclesNodes(vehicleListCopy);
    };

    const handleExpand = (item: INode, e: any) => {

        e.stopPropagation();

        const vehicleListCopy = [...vehiclesNodes];
        vehicleListCopy.forEach((listItem) => {
            if (listItem?.id === item?.id) {
                listItem.expanded = !item?.expanded;
            }
        });
        setVehiclesNodes(vehicleListCopy);
    };

    const onHideTree = () => {
        if (showVehiclesTree) {
            setShowVehiclesTree(false);
            setVehicleSearch("");
        }
    };

    const searchStockNo = (e: any) => {

        const stockNo = e?.target?.value;
        const result = initialVehicles?.filter(v => v.stockNo?.trim().toLowerCase().includes(stockNo?.trim().toLowerCase()))

        if (stockNo?.length > 0) {
            updateAndPageVehicles(result);
        } else {
            filterVehicles();
        }

    };

    const renderListItem = (item: INode) => {
        return (
            <>
                <ListItem key={item?.id} role={undefined} dense button>
                    <ListItemIcon onClick={(e) => handleSelected(item, e)}>
                        <Checkbox
                            indeterminate={item?.isChildSelected ? true : false}
                            edge="start"
                            className={classes.checkBox}
                            checked={isSelected(item)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": item?.id }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        id={item?.id}
                        primary={`${item?.name}`}
                        onClick={(e) => handleSelected(item, e)}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={(e) => handleExpand(item, e)}
                        >
                            {item?.expanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>

                <Collapse in={item?.expanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {item?.children?.map((child: INode) => {
                            return (
                                <ListItem
                                    button
                                    className={classes.nested}
                                    onClick={(e) => handleSelected(child, e)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={isSelected(child)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": child?.id }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={child?.name} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Collapse>
            </>
        );
    };

    const renderSelectedItems = (item: INode) => {
        return (
            <Grid item>
                <Chip
                    label={item?.displayName}
                    onDelete={(e) => handleSelected(item, e)}
                    className={classes.chip}
                />
            </Grid>
        );
    };

    return (
        <div className={classes.filterComponent}>

            <ClickAwayListener onClickAway={() => setShowVehiclesTree(false)}>
                <div className={classes.searchMainContainer}  >
                    <Paper className={classes.selectedItemsContainer}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            wrap="nowrap"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={selectedItems?.length > 0 ? 3 : 12} md={selectedItems?.length > 0 ? 1 : 12}>
                                <InputBase
                                    className={classes.searchInput}
                                    type={"search"}
                                    color="primary"
                                    placeholder={
                                        selectedItems?.length > 0 ? "Add More" : "Search Make, Model"
                                    }
                                    name={vehicleSearch}
                                    onChange={(e) => onSearchVehicle(e?.target?.value)}
                                    onFocus={(e) => {
                                        setShowVehiclesTree(true);
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowVehiclesTree(true);
                                    }}
                                />
                            </Grid>
                            {
                                selectedItems?.length > 0 &&
                                <Grid item container wrap="nowrap" justifyContent="flex-start" style={{ overflow: "hidden" }}>
                                    {
                                        selectedItems.map((item: INode) => renderSelectedItems(item))
                                    }
                                </Grid>
                            }
                            <Grid item >
                                {!!showVehiclesTree ? (
                                    <ArrowDropUp
                                        onClick={(e: any) => {
                                            setShowVehiclesTree((prev) => !prev);
                                        }}
                                    />
                                ) : (
                                    <ArrowDropDown
                                        onClick={(e: any) => {
                                            setShowVehiclesTree((prev) => !prev);
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Paper>

                    <Collapse
                        in={
                            (!searchLoading && displayList?.length > 0 && showVehiclesTree) ||
                            (!searchLoading &&
                                displayList?.length === 0 &&
                                vehicleSearch !== "")
                        }
                    >
                        <hr style={{ width: "98%" }} />

                        {!searchLoading && displayList?.length > 0 && showVehiclesTree && (
                            <Grid container item className={classes.searchVehiclesTree}>
                                <List className={classes.vehicleList}>
                                    {displayList.map((node) => {
                                        return renderListItem(node);
                                    })}
                                </List>
                            </Grid>
                        )}

                        {!searchLoading && displayList?.length === 0 && vehicleSearch && (
                            <Grid container justifyContent="center">
                                <Typography variant="h6" color="textSecondary">
                                    No results found
                                </Typography>
                            </Grid>
                        )}
                    </Collapse>
                    {searchLoading && !isNullOrUndefined(vehicleSearch) && (
                        <Grid container justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    )}
                </div>
            </ClickAwayListener>

            <Grid item container direction={orientation} spacing={3}>
                {/* YEARS  RANGES */}
                {yearRange && (
                    <Grid item xs={12} md={orientation === 'row' ? 4 : 12}>
                        <RangeSlider
                            reset={!filterApplied}
                            id="year_range"
                            label={"Years"}
                            websiteColors={websiteColors}
                            range={yearRange}
                            onChange={(val: number[]) => {
                                setSelectedYearRange(val);
                            }}
                        />
                    </Grid>
                )}

                {/* PRICE RANGES */}
                {priceRange && (
                    <Grid item xs={12} md={orientation === 'row' ? 4 : 12}>
                        <RangeSlider
                            isCurrencyVal={true}
                            reset={!filterApplied}
                            id="price_range"
                            range={priceRange}
                            websiteColors={websiteColors}
                            label={"Price"}
                            onChange={(val: number[]) => {
                                setSelectedPriceRange(val);
                            }}
                        />
                    </Grid>
                )}

                {/* Instalment RANGES */}
                {instalmentRange && (
                    <Grid item xs={12} md={orientation === 'row' ? 4 : 12}>
                        <RangeSlider
                            isCurrencyVal={true}
                            reset={!filterApplied}
                            id="installment_range"
                            label={"Instalment"}
                            websiteColors={websiteColors}
                            range={instalmentRange}
                            onChange={(val: number[]) => {
                                setSelectedInstalmentRange(val);
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container justifyContent={orientation === 'row' ? "space-between" : "center"} spacing={3} direction={orientation}>
                <Grid
                    item
                    container
                    direction={"row"}
                    xs={12}
                    md={orientation === 'row' ? 6 : 12}
                    spacing={orientation === 'row' ? 3 : 0}
                    justifyContent={orientation === 'row' ? "flex-start" : "space-between"}
                >
                    {/* Transmission */}
                    <Grid item>
                        <ToggleButtonGroup
                            className={classes.toggles}
                            value={selectedTransmission}
                            onChange={(e, val) => {
                                setSelectedTransmission(val);
                            }}
                            size="small"
                            aria-label="Select Transmission"
                        >
                            {transmissionOptions.map((option, i) => (
                                <ToggleButton
                                    value={option}
                                    className={classes.toggleBtn}
                                    aria-label="bold"
                                    key={`transmission_type_${i}`}
                                >
                                    {option}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                    {/* Fuel Type */}
                    <Grid item>
                        <ToggleButtonGroup
                            className={classes.toggles}
                            value={selectedFuelType}
                            onChange={(e, val) => setSelectedFuelType(val)}
                            size="small"
                            aria-label="Select Transmission"
                            
                        >
                            {fuelTypeOptions.map((option, i) => (
                                <ToggleButton
                                    value={option}
                                    className={classes.toggleBtn}
                                    aria-label="bold"
                                    key={`transmission_type_${i}`}
                                >
                                    {option}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={orientation === 'row' ? 6 : 12}
                    direction={orientation === 'row' ? orientation : 'column-reverse'}
                    justifyContent={orientation === 'row' ? "flex-end" : "center"}
                    alignItems='center'
                    spacing={orientation === 'row' ? 1 : 0}
                >

                    <Grid item xs={12} md={orientation === 'row' ? 3 : 12}>
                        <InputBase
                            className={classes.stockNoSearchField}
                            id="stockNo"
                            placeholder="Search stock no."
                            type="search"
                            color="primary"
                            onBlur={searchStockNo}
                            onChange={searchStockNo}
                        />
                    </Grid>

                    {
                        filterApplied &&
                        <Grid item xs={12} md={orientation === 'row' ? 2 : 12}  >
                            <Button
                                className={classes.btn2ActiveClear}
                                style={{
                                    marginTop: orientation === 'row' ? 0 : 10
                                }}
                                onClick={() => clearFilter()}
                            >
                                Reset
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} md={orientation === 'row' ? 2 : 12}>
                        <Button
                            className={classes.btn2Active}
                            onClick={() => filterVehicles()}
                        >
                            Search
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    );
};

export default VehiclesFilterV2;
