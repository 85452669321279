import React, { FC } from "react";
import { Field } from "formik";
import ImageUploader from './ImageUploader'
import Resizer from "react-image-file-resizer"

const IMAGE_UPLOADER_CONFIG = {
    maxWidth: 1024,
    maxHeight: 1024,
    compressFormat: 'JPEG',
    quality: 100,
    rotation: 0,
    outputType: 'base64'
};

interface Props {
    title: string;
    fieldName: string;
    required?: boolean;
    disabled?: boolean;
    error?: any;
    errorMsg?: string;
    className?: string;
    onChange?: Function;
}

const ImageUploaderInput: FC<Props> = ({
    title,
    fieldName,
    required = false,
    disabled = false,
    error,
    errorMsg,
    className,
    onChange
}) => (
    <Field name={fieldName} id={fieldName} type="text">
        {   //@ts-ignore
            ({ field: { value }, form: { setFieldValue } }) => (
            <ImageUploader 
                title={title}
                disabled={disabled}
                required={required}
                className={className}
                //@ts-ignore
                onDropFile={(file) => {

                    let compressFormat = IMAGE_UPLOADER_CONFIG.compressFormat;

                    if (file.type === "image/png") {
                        compressFormat = 'PNG';
                    }

                    Resizer.imageFileResizer(
                        file,
                        IMAGE_UPLOADER_CONFIG.maxWidth,
                        IMAGE_UPLOADER_CONFIG.maxHeight,
                        compressFormat,
                        IMAGE_UPLOADER_CONFIG.quality,
                        IMAGE_UPLOADER_CONFIG.rotation,
                        uri => {
                            setFieldValue(fieldName, uri)
                            if (onChange) {
                                onChange(uri);
                            }
                        },
                        IMAGE_UPLOADER_CONFIG.outputType
                    );

                }}
                id={fieldName}
                image={value}
                error={error}
                errorMsg={errorMsg}
                clear={() => setFieldValue(fieldName, '')} />
        )}
    </Field>
);

export default ImageUploaderInput;